import Header from './Header.js';
import FeatureSection from './FeaturesSection.js';
import AboutSection from './AboutSection.js';
import FAQSection from './FAQSection.js';
import Footer from './Footer.js';
import HeaderBody from './HeaderBody';

const Home = () => {
	return (
		<div className="home">
			<Header>
				<HeaderBody />
			</Header>
			<FeatureSection />
			<AboutSection />
			<FAQSection />
			<Footer />
			<img className="p5Canvas" src="/PW_Page_background.gif" />
		</div>
	);
};

export default Home;
