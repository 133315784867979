import Head from "next/head";
import { useEffect, useState } from "react";
import Home from "../components/Landing/Home.js";
import Loading from "../components/Landing/Loading";

export default function Index() {
  const [loading, setLoading] = useState(true);

  const handleResize = () => {
    document.getElementsByTagName("html")[0].style.fontSize =
      window.innerWidth <= 700
        ? "12px"
        : `${(16 / 1440) * window.innerWidth}px`;
  };

  useEffect(() => {
    let x = setInterval(() => {
      if (window) {
        console.log(window);
        window.addEventListener("resize", handleResize);
        handleResize();
        setLoading(false);
        console.log(loading);
        clearInterval(x);
      }
    }, 1500);
  }, []);

  return (
    <>
      <Head>
        <title>Price Errors</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className="homepage">
        {loading ? <div style={{ display: loading ? "absolute" : "none" }}>
          <Loading />
        </div> : <div
          style={{
            visibility: loading ? "hidden" : "visible",
            transition: "3s",
            opacity: loading ? "0" : "1",
          }}
        >
          <Home />
        </div>}

      </div>
    </>
  );
}
